.card {
  border: solid 1px var(--adm-color-border);
  margin: 20px 0px;

  .card-title-right {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  .table-container {
    display: flex;
    overflow-x: auto;
  }

  .fixed-column {
    flex: 0 0 auto;
    overflow: hidden;
  }

  .scrollable {
    flex: 1;
    overflow-x: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    height: 40px;
    text-align: left;
    padding: 8px;
  }

  th {
    // font-size: 16px;
    white-space: nowrap;
  }

  .table-title {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  td {
    // font-size: 14px;
    white-space: nowrap;
  }

  .action {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    white-space: nowrap;

    a {
      font-weight: bold;
    }
  }

  .placeholder {
    color: var(--placeholder-color);
  }

  .stepper {
    width: 200px;
    --height: 80px;
  }
}

.popup-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  font-size: 16px;
  font-weight: 600;
}

.popup-button {
  display: flex;
  flex-direction: column;
  gap: 8px;
}