body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* PC Style */
@media (min-width: 768px) {
  body {
    width: 450px;
    margin: 10px auto;
    border: 2px solid var(--adm-color-border);
    border-radius: 10px;
  }

  .body {
    height: calc(100vh - 200px);
  }

  .content {
    margin-bottom: 80px;
  }

  .tab-bar {
    z-index: 2;
    position: absolute;
    bottom: 47px;
    width: 450px;
    height: 60px;
    border-radius: 0px 0px 10px 10px;
    border-top: 2px solid var(--adm-color-border);
  }
}

/* Mobile Style */
@media (max-width: 767px) {
  body {
    width: 100%;
  }

  .body {
    height: calc(100vh - 140px);
  }

  .content {
    margin-bottom: 20px;
  }

  .tab-bar {
    z-index: 2;
    position: fixed;
    bottom: -1px;
    left: 0;
    height: 60px;
    width: 100%;
    border-top: 2px solid var(--adm-color-border);
  }
}