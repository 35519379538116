.app {
    height: calc(100vh - 58px);
    .navigator {
        height: 60px;
        border-bottom: solid 2px var(--adm-border-color);

        .title-right {
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: flex-end;

            .button {
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .title {
            font-size: 18px;
        }

        .subtitle {
            font-size: 14px;
        }
    }

    .adm-list-body {
        border-top: 0px;
        border-bottom: 0px;
    }

    .body {
        padding: 20px 20px 0px 20px;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;

        .expected {
            .label {
                width: 120px;
            }
        }

        .content {
            .link-list-card {
                .adm-list-header {
                    font-size: 16px;
                    font-weight: bold;
                }

                .adm-list-body {
                    border: solid 1px var(--adm-color-border);
                }
            }

            .adm-list-card {
                margin: 0px;
                margin-bottom: 12px;
            }
        }
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

}

.light {
    .tab-bar {
        background: #ffffff;
    }
}

.dark {
    .tab-bar {
        background: #1a1a1a;
    }
}